
import React from "react";
import { Row, Col, Alert, Card, CardBody,Container } from "reactstrap";

// Redux

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import SEO from "../components/seo";
import { Link } from "gatsby";





  export default function ForgetPassword(props) {
    return (
      <React.Fragment>
        <SEO/>
        <div className="home-btn d-none d-sm-block">
          <a href="/" className="text-dark"><i className="bx bx-home h2"></i></a>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
              <div className="d-flex justify-content-center">
                      <a href="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src="/images/paraffin-logo.png" alt="" className="rounded-circle" height="54" />
                          </span>
                        </div>
                      </a>
                    </div>
                <Card className="overflow-hidden">
                  <div>
                    <Row>
                      <Col className="col-8">
                        <div className="text-primary p-2">
                          <h5 className="text-primary">Forgot your password?</h5>
                          <p>Enter your email to reset your password</p>
                        </div>
                      </Col>
                      {/* <Col className="col-4 align-self-end">
                        <img src="/images/profile-img.png" alt="" className="img-fluid" />
                      </Col> */}
                    </Row>
                  </div>
                  <CardBody className="pt-0">

                    <div className="p-2">

                      {props.forgetError && props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal pt-2"
                        onValidSubmit={()=>""}
                      >

                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <Row className="form-group">
                          <Col className="text-right">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                              </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link
                      to="/login"
                      className="font-weight-medium text-primary"
                    >
                      Login
                      </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }



